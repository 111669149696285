

































































import { Component, Vue, Watch } from 'vue-property-decorator';
import FreeTrialDlg from './components/FreeTrialDlg.vue';
import Header from './components/Header.vue';
import { loadingApply } from '@/api/home';
import utils from '@/utils/index';
import common from '@/utils/common';

@Component({
    name: 'EGGAlaiPackage',
    components: {
        FreeTrialDlg,
        Header
    }
})
export default class EGGAlaiPackage extends Vue {
    private showFreeTrailDlg = false;
    private cardClassName = '';
    private packageData:any = [];
    private tabName = 'featurePackage';

    @Watch('$i18n.locale')
    watchLocaleChange() {
        if (this.tabName === 'featurePackage') this.packageData = this.dataList;
        else if (this.tabName === 'addedOnFeature') this.packageData = this.addOnFeatureDataList;
    }

    get dataList() {
        return [
            {
                titleName: this.$i18n.t('package.package'),
                expand: true,
                data: [
                    {
                        name: `15000 ${this.$i18n.t('home.baht')} / ${this.$i18n.t('package.sixMonths')}`
                    },
                    {
                        name: `50 ${this.$i18n.t('package.numberOfUsers')}`
                    },
                    {
                        name: `5.0 GB ${this.$i18n.t('package.storageSpace')}`
                    },
                    {
                        name: `${this.$i18n.t('package.unlimited')} ${this.$i18n.t('package.transactions')}`
                    }
                ]
            },
            {
                titleName: this.$i18n.t('package.dataFreeSupport'),
                expand: false,
                data: [
                    {
                        name: `100,000 ${this.$i18n.t('package.maximumSkuUploaded')}`
                    },
                    {
                        name: `10,000 ${this.$i18n.t('package.maximumSkuExport')}`
                    },
                    {
                        name: `10,000 ${this.$i18n.t('package.iamSkuFreeSelection')}`
                    },
                    {
                        name: `10,000 ${this.$i18n.t('package.freeTransactionExport')}`
                    }
                ]
            },
            {
                titleName: this.$i18n.t('package.basicFunction'),
                expand: false,
                data: [
                    {
                        name: this.$i18n.t('package.basicFeature1')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature2')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature3')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature4')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature5')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature6')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature7')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature8')
                    },
                    {
                        name: this.$i18n.t('package.basicFeature9')
                    }
                ]
            },
            {
                titleName: this.$i18n.t('package.salesManagementName'),
                expand: false,
                data: [
                    { name: this.$i18n.t('package.salesManagementName1') },
                    { name: this.$i18n.t('package.salesManagementName2') },
                    { name: this.$i18n.t('package.salesManagementName3') },
                    { name: this.$i18n.t('package.salesManagementName7') },
                    { name: this.$i18n.t('package.salesManagementName8') }
                ]
            },
            // {
            //     titleName: this.$i18n.t('package.purchasingManagementName'),
            //     expand: false,
            //     data: [
            //         { name: this.$i18n.t('package.purchasingManagement1') }
            //     ]
            // },
            {
                titleName: this.$i18n.t('package.productInventoryManagementName'),
                expand: false,
                data: [
                    { name: this.$i18n.t('package.productInventoryManagement1') },
                    { name: this.$i18n.t('package.productInventoryManagement2') },
                    { name: this.$i18n.t('package.productInventoryManagement4') }
                ]
            },
            {
                titleName: this.$i18n.t('package.counterSales'),
                expand: false,
                data: [
                    {
                        name: this.$i18n.t('package.counterFeature1')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature2')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature3')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature4')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature5')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature10')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature11')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature6')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature7')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature8')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature12')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature13')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature14')
                    },
                    {
                        name: this.$i18n.t('package.counterFeature15')
                    }
                ]
            },
            {
                titleName: this.$i18n.t('home.mobileCrm'),
                expand: false,
                data: [
                    {
                        name: this.$i18n.t('package.mobileFeature1')
                    },
                    {
                        name: this.$i18n.t('package.mobileFeature2')
                    },
                    {
                        name: this.$i18n.t('package.mobileFeature3')
                    },
                    {
                        name: this.$i18n.t('package.mobileFeature4')
                    },
                    {
                        name: this.$i18n.t('package.mobileFeature5')
                    },
                    {
                        name: this.$i18n.t('package.mobileFeature6')
                    }
                ]
            },
            {
                titleName: this.$i18n.t('home.introTitle3'),
                expand: false,
                data: [
                    {
                        name: this.$i18n.t('package.purchaseFeature1')
                    },
                    {
                        name: this.$i18n.t('package.purchasingManagement1')
                    },
                    {
                        name: this.$i18n.t('package.purchaseFeature2')
                    },
                    {
                        name: this.$i18n.t('package.purchaseFeature3')
                    },
                    {
                        name: this.$i18n.t('package.purchaseFeature5')
                    },
                    {
                        name: this.$i18n.t('package.purchaseFeature4')
                    }
                ]
            },
            {
                titleName: this.$i18n.t('home.introTitle4'),
                expand: false,
                data: [
                    {
                        name: this.$i18n.t('package.dashboardFeature1')
                    },
                    {
                        name: this.$i18n.t('package.dashboardFeature2')
                    },
                    {
                        name: this.$i18n.t('package.dashboardFeature7')
                    },
                    {
                        name: this.$i18n.t('package.dashboardFeature3')
                    },
                    {
                        name: this.$i18n.t('package.dashboardFeature4')
                    },
                    {
                        name: this.$i18n.t('package.dashboardFeature5')
                    },
                    {
                        name: this.$i18n.t('package.dashboardFeature6')
                    }
                ]
            },
            {
                titleName: this.$i18n.t('home.introTitle5'),
                expand: false,
                data: [
                    {
                        name: this.$i18n.t('package.apiFeature1')
                    },
                    {
                        name: this.$i18n.t('package.apiFeature2')
                    },
                    {
                        name: this.$i18n.t('package.apiFeature3')
                    }
                    // {
                    //     name: this.$i18n.t('package.apiFeature4')
                    // },
                    // {
                    //     name: this.$i18n.t('package.apiFeature5')
                    // },
                    // {
                    //     name: this.$i18n.t('package.apiFeature6')
                    // }
                ]
            }
        ];
    }

    get addOnFeatureDataList() {
        return [
            {
                titleName: this.$i18n.t('package.additionalDataManagementName'),
                expand: true,
                data: [
                    { name: this.$i18n.t('package.additionalDataManagement1') },
                    { name: this.$i18n.t('package.additionalDataManagement2') }
                ]
            }
        ];
    }

    mounted() {
        window.addEventListener('scroll', this.scroll);
        window.scrollTo(0, 0);
        this.packageData = this.dataList;
    }

    destroy() {
        window.removeEventListener('scroll', this.scroll);
    }

    private changeTab(tabName: any) {
        this.tabName = tabName;
        if (tabName === 'featurePackage') this.packageData = this.dataList;
        else if (tabName === 'addedOnFeature') this.packageData = this.addOnFeatureDataList;
    }

    private scroll(e:any) {
        utils.debounce(this.handleScroll(e), 1)();
    }

    private handleExpandAll() {
        this.packageData.forEach((item:any) => { item.expand = true; });
    }

    private handleFoldAll() {
        this.packageData.forEach((item:any) => { item.expand = false; });
    }

    private handleFreeTrial() {
        this.showFreeTrailDlg = true;
        loadingApply({ country: '1001' });
        window.gtag('event', 'free_trial');
        utils.fbq('trackCustom', 'free_trial');
    }

    private handleScroll(e:any) {
        const scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
        if (scrollTop > 358) {
            this.cardClassName = 'card-fixed';
        } else {
            this.cardClassName = '';
        }
    }
}
